<template>
    <b-card title="Payment">
        <b-form>
            <b-row>
                <b-col md="3">
                    <b-form-group label="Account Group">
                        <v-select v-model="accountGroupName" placeholder="Select Account Group"
                            @input="getAccounts(accountGroupName.id)" label="accountHeadName"
                            :options="accountGroupNameOptions"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Account *">
                        <v-select v-model="accountName" placeholder="Select Account" label="accountName"
                            :options="accountNameOptions"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Paid To *">
                        <v-select v-model="paidToVendor" placeholder="Select Vendor" label="businessName"
                            :options="paidToVendorOption"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-button variant="secondary" v-b-toggle.sidebar-right class="mt-2 mr-2">Add New Vendor</b-button>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Invoice No *">
                        <b-form-input v-model="invoiceNo" placeholder="Please Enter Invoice No"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Amount *">
                        <b-form-input v-model="amount" placeholder="Please Enter Amount"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Payment Mode *">
                        <v-select v-model="paymentModeType" placeholder="Select Payment Mode"
                            :options="paymentModeTypeOptions"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Payment Description">
                        <b-form-input v-model="paymentDescription"
                            placeholder="Please Enter Payment Description"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Narration">
                        <b-form-input v-model="transactionDescription"
                            placeholder="Please Enter Narration"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-button block variant="primary" @click="paymentSubmit" class="mt-2 ">Pay</b-button>
                </b-col>
            </b-row>
        </b-form>
        
            <add-vendor :getVendors="getVendors"></add-vendor>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BRow, BCol,VBToggle, BFormGroup, BButton, BButtonGroup, BFormInput, BFormSelect, BPagination, BSidebar
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import api from '@/store/api'
import AddVendor from './AddVendor.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        VueGoodTable, vSelect,VBToggle, BCard, BForm, BRow, BCol, BFormGroup, BSidebar,
        BButton, BButtonGroup, BFormInput, flatPickr, BFormSelect, BPagination, AddVendor
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            accountGroupNameOptions: [],
            accountGroupName: '',
            accountNameOptions: [],
            accountName: '',
            paidToVendorOption: [],
            paidToVendor: '',
            invoiceNo: '',
            amount: 0,
            paymentModeTypeOptions: [],
            paymentModeType: '',
            paymentDescription: '',
            transactionDescription: '',
            defaultConfiguration: JSON.parse(localStorage.getItem('defaultConfiguration')),

        }
    },
    methods: {
        getAccountGroups() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/finance/accountGroups', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.accountGroupNameOptions = response.data;
                    // self.getAccounts(response.data.id);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getAccounts(id) {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/finance/accountsByGroupId/' + id, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.accountNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getVendors() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/finance/vendors', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.paidToVendorOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getPaymentModeType() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/paymentModeTypes', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    const array = response.data;

                    //Remove E_WALLET
                    const index = array.indexOf('E_WALLET');
                    if (index > -1) {
                        array.splice(index, 1);
                    }

                    //Remove CRYPTOCURRENCY
                    const index1 = array.indexOf('CRYPTOCURRENCY');
                    if (index1 > -1) {
                        array.splice(index1, 1);
                    }
                    self.paymentModeTypeOptions = array;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        paymentSubmit() {
            let self = this;
            if (self.accountName.id > 0 && self.paidToVendor.id > 0 && !self.invoiceNo == '' && self.amount > 0 && !self.paymentModeType == '') {
                var axios = require('axios');
                var data = JSON.stringify(
                    {
                        "accountId": self.accountName.id,
                        "amount": self.amount,
                        "currency": self.defaultConfiguration.currency,
                        "invoiceNo": self.invoiceNo,
                        "paymentMode": self.paymentModeType,
                        "paymentDescription": self.paymentDescription,
                        "storeId": self.$store.state.storeId,
                        "transactionDescription": self.transactionDescription,
                        "vendorId": self.paidToVendor.id,
                        "transactionType": "DEBIT",
                    }
                );
                axios(api.getApi('Post', '/finance/accounts/createTransaction', data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Payment Successfully!`,
                                icon: 'SaveIcon',
                                variant: 'success',
                            },
                        })
                        self.resetForm();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                this.$swal({
                    title: '',
                    text: "Please Select All The Manditory Fields.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }

        },
        resetForm(){
            this.accountGroupName= '';
            this.accountName= '';
            this.paidToVendor= '';
            this.invoiceNo= '';
            this.amount= 0;
            this.paymentModeType= '';
            this.paymentDescription= '';
            this.transactionDescription= '';
        }

    },
    created() {
        this.getAccountGroups();
        this.getVendors();
        this.getPaymentModeType();
    }

}
</script>

<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
